import { config } from '../cognitocmsapi/default_config.js'

config.devURL = `https://morganpark.${config.devbase}`
config.prodURL = 'https://cms.morganparkraceway.com.au'
config.siteURL = 'https://app.morganparkraceway.com.au'
config.baseURL = config.devbase == '' ? config.prodURL : config.devURL

config.isEcommerce = false
config.sentryDsn = 'https://93cd3d47d11947ad92b8ba8f562166ff@glitchtip.logger.jm1.au/15'

config.login.code_only = true
config.signUp.ask_date_of_birth = true
config.profile.edit_date_of_birth = true

export { config }
