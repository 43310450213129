<template>
  <div class="mx-auto max-w-lg p-4">
    <div class="py-4">
      <img class="mx-auto" src="../images/logo.svg">
    </div>
    <div v-if="!membershipStore.is_approved" class="text-center text-3xl font-semibold text-brand-300">
      Welcome
    </div>
    <div v-else-if="!membershipStore.hasPrior" class="text-center text-3xl font-semibold text-brand-300">
      Membership Pending Payment
    </div>
    <div v-else-if="membershipStore.needsRenewal" class="text-center text-3xl font-semibold text-brand-300">
      Your membership has expired
    </div>
    <div v-else-if="membershipStore.valid_to && membershipStore.canRenew" class="flex flex-col items-center">
      <span class="text-xl text-brand-300">Your membership will expire at</span>
      <span class="text-4xl text-brand-300">{{ membershipStore.valid_to_formatted }}</span>
    </div>
    <div class="grid cursor-pointer select-none grid-cols-2 gap-2 py-2 text-base text-brand-300 xs:text-xl">
      <cgn-button v-if="!membershipStore.is_approved" url="/apply" fullwidth size-large class="col-span-2">
        <span class="flex items-center justify-center gap-1"><i-heroicons-solid:cash /> Apply to become a member now</span>
      </cgn-button>
      <cgn-button v-else-if="membershipStore.canRenew" url="/renew" fullwidth size-large class="col-span-2">
        <span class="flex items-center justify-center gap-1"><i-heroicons-solid:cash />
          <template v-if="membershipStore.hasPrior">
            Renew your membership now
          </template>
          <template v-else>
            Purchase membership now
          </template>
        </span>
      </cgn-button>
      <cgn-button url="/calendar" fullwidth size-large class="col-span-2">
        <span class="flex items-center justify-center gap-1"><i-heroicons-solid:calendar /> Events</span>
      </cgn-button>
      <cgn-button v-if="!membershipStore.needsRenewal" url="/membershipcard" fullwidth size-large>
        <span class="flex items-center justify-center gap-1"><i-heroicons-solid:credit-card /> Membership card</span>
      </cgn-button>
      <cgn-button v-if="!membershipStore.needsRenewal" url="/vehicles" fullwidth size-large>
        <span class="flex items-center justify-center gap-1"><i-ion:car-sport /> Garage</span>
      </cgn-button>
      <cgn-button :url="website_url" newtab fullwidth size-large class="col-span-2">
        <span class="flex items-center justify-center gap-1"><i-heroicons-solid:globe-alt /> Website</span>
      </cgn-button>
      <cgn-button url="/profile" fullwidth size-large class="col-span-2">
        <span class="flex items-center justify-center gap-1"><i-heroicons-solid:user /> My profile</span>
      </cgn-button>

      <router-link to="/logout" class="fixed bottom-2 right-2 rounded-xl border-2 border-brand-300 p-2">
        <span class="flex items-center gap-1">
          <i-heroicons-solid:logout />
        </span>
      </router-link>
    </div>
  </div>
</template>

<script setup lang="ts">
const membershipStore = useClubMemberStore()

const website_url = ref('https://morganparkraceway.com.au/')

onMounted(() => {
  membershipStore.refresh()
})
</script>

<route lang="yaml">
meta:
  requiresAuth: true
</route>
