import { ViteSSG } from 'vite-ssg'
import { setupLayouts } from 'virtual:generated-layouts'

// import Previewer from 'virtual:vue-component-preview'
import App from './App.vue'
import type { UserModule } from './types'
import generatedRoutes from '~pages'
import './tailwind.css'
import { CognitoSitemap } from '~cognito/models/Cognito/Sitemap'
import { defineCustomElement } from '~cognito/defineCustomElementWithStyles'

const customElementList = [
]

const routes = setupLayouts(generatedRoutes)

// https://github.com/antfu/vite-ssg
export const createApp = ViteSSG(
  App,
  { routes, base: import.meta.env.BASE_URL },
  (ctx) => {
    // install all modules under `modules/`
    Object.values(import.meta.glob<{ install: UserModule }>('./modules/*.ts', { eager: true }))
      .forEach(i => i.install?.(ctx))
    // install all modules under `~cognito/modules/`
    Object.values(import.meta.glob<{ install: UserModule }>('~cognito/modules/*.ts', { eager: true }))
      .forEach(i => i.install?.(ctx))
    // ctx.app.use(Previewer)

    // install all custom elements
    customElementList.forEach((element) => {
      customElements.define(element.tag, defineCustomElement(element.component, {
        plugins: [
          ctx.router,
          ctx.pinia,
        ],
      }))
    })
  },
)

export async function includedRoutes() {
  // Pull routes from sitemap
  const sitemap = await new CognitoSitemap().getRoutes()
  const newroutes = sitemap.map((e) => {
    if (e.url === '/gallery') {
      return ''
    }
    return e.url
  })
  newroutes.push('/login')

  return newroutes
}
